import React from 'react';

import { Header, Body, Footer } from './layout-blocks';
import PropTypes from 'prop-types';
import 'scss/default-theme/theme.scss';

export default function Layout({ indication, children, className, ...props }) {
  return (
    <div {...props} className={`page ${indication} ${className}`}>
      <div className="content-body">
        <Header indication={indication} />
        <Body indication={indication}>{children}</Body>
        <Footer indication={indication} />
      </div>
    </div>
  );
}

Layout.propTypes = {
  indication: PropTypes.oneOf(['hcp', 'patient']).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
