import React from 'react';
import { Logo } from 'components';
import PropTypes from 'prop-types';
import imgLogo from 'images/averitas-logo_new.png';
import svgImg from 'images/wave-shaped.png';

const PatientFooter = () => {
  return (
    <div className="wrapper">
      <div className="svg-block">
        <img src={svgImg} alt="yelow svg" />
      </div>
      <div className="main-footer-content-wrapper">
        <div className="gutter-all footer-content">
          <div className="inner-content">
            <Logo
              image={imgLogo}
              url="https://www.averitaspharma.com"
              target="_blank"
              className=""
            />
            <p className="copyright-text">
              QUTENZA<sup>&reg;</sup> is a registered trademark of Averitas
              Pharma, Inc. <br />
              © 2024 Averitas Pharma, Inc. All rights reserved.
              <br />
              QZA-01-22-0022 v5.0&nbsp;May 2024
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Footer({ indication, ...props }) {
  return (
    <footer {...props} className="footer-wrapper">
      {indication === 'patient' && <PatientFooter />}
    </footer>
  );
}

Footer.propTypes = {
  indication: PropTypes.string.isRequired,
};
