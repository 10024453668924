import React from 'react';
import { Logo } from 'components';
import PropTypes from 'prop-types';
import mqcLogo from 'images/mqc-logo.svg';
import qutenzaLogo from 'images/qutenza-logo.svg';

const PatientHeader = () => {
  return (
    <div className="full-wrapper">
      <div className="top-border-block" />
      <div className="main-header-content-wrapper">
        <div className="header-content">
          <div className="main-title">
            <h1>
              My QUTENZA Connect Cost Savings Program<sup>&#42;</sup>
            </h1>
          </div>
          <div className="logo-wrap">
            <Logo
              image={mqcLogo}
              url="https://myqutenzaconnect.com/"
              className="brand-logo"
            />
            <Logo
              image={qutenzaLogo}
              url="https://www.qutenza.com/"
              className="brand-logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Header({ indication, ...props }) {
  return (
    <header {...props} className="header-wrapper">
      {indication === 'patient' && <PatientHeader />}
    </header>
  );
}

Header.propTypes = {
  indication: PropTypes.string.isRequired,
};
